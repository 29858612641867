import React from 'react';
import { Authenticator, Image, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import MainPanel from './ui/MainPanel';
import Banner from './ui/Banner';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import logo from './images/CNMF logo.png';
import { I18n } from 'aws-amplify';

I18n.putVocabulariesForLanguage('en', {
  Code: 'MFA Code',
  'Confirm TOTP Code': 'Confirm MFA Code',
  'Setup TOTP': 'Setup MFA',
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const components = {
  Header() {
    return (
      <View
        textAlign='center'
        backgroundColor='rgb(0, 11, 41)'
        backgroundImage='linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))'
      >
        <Image alt='Amplify logo' src={logo} marginTop='16px' />
      </View>
    );
  },
};

class App extends React.Component {
  componentDidMount() {
    alert(
      'You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized use only. By using this IS (which includes any device attached to this IS), you consent to the following conditions:\r\rThe USG routinely intercepts and monitors communications on this IS for purposes including, but not limited to, penetration testing, COMSEC monitoring, network operations and defense, personnel misconduct (PM), law enforcement (LE) , and counterintelligence (CI) investigations.\r\rAt any time, the USG may inspect and seize data stored on this IS.\r\rCommunications using, or data stored on, this IS are not private, are subject to routing monitoring, interception, and search, and may be disclosed or used for any USG-authorized purpose.\r\rThis IS includes security measures (e.g., authentication and access controls) to protect USG interests--not for your personal benefit or privacy.\r\rNotwithstanding the above, using this IS does not constitute consent to PM, LE or CI investigative searching or monitoring of the content of privileged communications, or work product, related to personal representation or services by attorneys, psychotherapists, or clergy, and their assistants.\r\rSuch communications and work product are private and confidential.'
    );
  }

  render() {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <>
          <Banner />
          <Authenticator hideSignUp={true} components={components}>
            {() => (
              <main>
                <MainPanel />
              </main>
            )}
          </Authenticator>
          <div
            style={{
              position: 'fixed',
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            <Banner />
          </div>
        </>
      </ThemeProvider>
    );
  }
}

export default App;
