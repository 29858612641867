import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete";
import API from "./ApiWrapper";
import CloseIcon from "@mui/icons-material/Close";

export default function DeleteDialog({
  distributionId,
  onSubmitCallback,
  childToParent,
}) {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    deleteData(distributionId);
    setOpen(false);
  };

  const deleteData = async (distributionId) => {
    const path = `/rpas/${distributionId}`;
    return API.del(path, {})
      .then(function (result) {
        childToParent(result);
        onSubmitCallback();
      })
      .catch((err) => {
        childToParent(err);
        onSubmitCallback();
      });
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          onClick={() => setOpen(true)}
          color="primary"
          sx={{ ml: 2 }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete RPaS
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          <DialogContentText>
            Are you sure you want to delete{" "}
            {distributionId && distributionId.substring(0, 2) === "NE"
              ? distributionId.substring(0, 2)
              : distributionId}
            ?
          </DialogContentText>
          <DialogContentText style={{ fontWeight: "bold", marginTop: 8 }}>
            In order to delete a distribution, it must be in a DISABLED state.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button
            onClick={handleDelete}
            variant="contained"
            sx={{
              backgroundColor: '#fac90b',
              '&:hover': {
                backgroundColor: '#FEDB00',
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
