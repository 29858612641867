import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material/";
import API from "./ApiWrapper";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function UpDateDialog({
  stackName,
  distributionId,
  status,
  onSubmitCallback,
  childToParent,
}) {
  const [open, setOpen] = useState(false);

  const handleUpdate = () => {
    putData(distributionId);
    setOpen(false);
  };

  const putData = async (distributionId) => {
    let putPath = `/rpas/${distributionId}`;
    API.put(putPath, {})
      .then(function (result) {
        childToParent(result);
        onSubmitCallback();
      })
      .catch((err) => {
        childToParent(err);
        onSubmitCallback();
      });
  };

  let display;
  if (status === "false") {
    display = (
      <Button
        variant="contained"
        color="error"
        onClick={() => setOpen(true)}
      >
        Disabled
      </Button>
    );
  }
  if (status === "true") {
    display = (
      <Button
        variant="contained"
        color="success"
        onClick={() => setOpen(true)}
      >
        Enabled
      </Button>
    );
  }
  if (status === "in progress") {
    display = <CircularProgressWithLabel />;
  }

  return (
    <div>
      {display}
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {status === "true" ? "Disable " : "Enable "}RPaS
          <IconButton onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          <DialogContentText>
            Are you sure you want to{" "}
            {status === "true" ? "disable " : "enable "}{" "}
            {stackName && stackName.substring(0, 2) === "NE"
              ? stackName.substring(0, 2)
              : stackName}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button 
            onClick={handleUpdate} 
            variant="contained" 
            sx={{
              backgroundColor: '#fac90b',
              '&:hover': {
                backgroundColor: '#FEDB00',
              },
            }}
          >
            {status === "true" ? "Disable " : "Enable "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function CircularProgressWithLabel() {
  const [progress, setProgress] = useState(0);
  const increment = 100 / 112.5;

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + increment;
        if (nextProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return nextProgress;
      });
    }, 2000); // 2 second delay

    return () => clearInterval(timer);
  }, []);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress 
        variant="determinate" 
        color='primary'          
        thickness={4}
        size={30}
        value={progress}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="primary">
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
