import { Flex, Image, useTheme } from "@aws-amplify/ui-react";
import Logo from "../images/CNMF logo.png";

export function Header() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center">
      <Image
        src={Logo}
        alt="RPAS logo"
        padding={tokens.space.medium}
        height="100px"
        width="100px"
      />
    </Flex>
  );
}
