import React, { Component } from "react";
import { Auth } from "aws-amplify";

class UserName extends Component {
  constructor() {
    super();
    this.state = { currentUser: "" };
  }

  async componentDidMount() {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
      this.setState({ currentUser: currentAuthenticatedUser.username });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return <div>User: {this.state.currentUser}</div>;
  }
}

export default UserName;
