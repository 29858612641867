import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

function AppWrapper({ children }) {
    const navigate = useNavigate();

    useEffect(() => {
        const sessionTimeout = 15 * 60 * 1000;
        let lastActivity = Date.now();
        const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

        const activityHandler = () => {
            lastActivity = Date.now();
        };

        events.forEach((event) => {
            document.addEventListener(event, activityHandler);
        });

        const sessionCheckInterval = setInterval(() => {
            if (Date.now() - lastActivity > sessionTimeout) {
                Auth.signOut().then(() => {
                    navigate("/");
                });
            }
        }, 1000);

        return () => {
            clearInterval(sessionCheckInterval);
            events.forEach((event) => {
                document.removeEventListener(event, activityHandler);
            });
        };
    }, [navigate]);

    return <>{children}</>;
}

export default AppWrapper;
