import React, { useState, useEffect, useRef, useCallback } from 'react';
import API from './ApiWrapper';
import { trackPromise } from 'react-promise-tracker';
import Grid from '@mui/material/Grid';
import MenuAppBar from './MenuAppBar';
import DistributionsTable from './DistributionsTable';

export default function MainPanel() {
  const [distributions, setDistributions] = useState([]);

  useEffect(() => {
    getDistributionData();
  }, []);

  const getDistributionData = async () => {
    return trackPromise(
      API.get('/rpas', {}).then(function (result) {
        if (result.statusCode === 200) {
          setDistributions(JSON.parse(result.body));
        }
      })
    ).catch((err) => {
      console.error(err);
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <MenuAppBar />
        </Grid>
        <Grid item xs={10}>
          <DistributionsTable
            distributions={distributions}
            setDistributions={setDistributions} 
            getDistributionData={getDistributionData}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
