import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import './index.css';
import AppWrapper from './AppAuthWrapper';
Amplify.configure(config);

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <AppWrapper>
        <Routes>
          <Route path='/' element={<App />} />
        </Routes>
      </AppWrapper>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
