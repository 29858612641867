import React from "react";
import "@aws-amplify/ui-react/styles.css";

class Banner extends React.Component {
  render() {
    return <div className="banner">UNCLASSIFIED</div>; 
  }
}

export default Banner;
