import { API as amplifyApi, Auth } from 'aws-amplify';

const API_NAME = 'rpas';

const API = {
  async get(path, init = {}) {
    await addAuthHeader(init);
    return amplifyApi.get(API_NAME, path, init);
  },

  async post(path, init = {}) {
    await addAuthHeader(init);
    return amplifyApi.post(API_NAME, path, init);
  },

  async put(path, init = {}) {
    await addAuthHeader(init);
    return amplifyApi.put(API_NAME, path, init);
  },

  async patch(path, init = {}) {
    await addAuthHeader(init);
    return amplifyApi.del(API_NAME, path, init);
  },

  async del(path, init = {}) {
    await addAuthHeader(init);
    return amplifyApi.del(API_NAME, path, init);
  },
};

async function addAuthHeader(init) {
  init.headers = {
    ...(init && init.headers),
    Authorization: await getToken(),
  };
}

async function getToken() {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export default API;
