import React, { useState } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import API from './ApiWrapper';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export default function CreateDialog({ getDistributionData, setResult, setDistributions, distributions }) {
  const [open, setOpen] = useState(false);
  const [originPath, setOriginPath] = useState('');
  const [originPathError, setOriginPathError] = useState(false);
  const [originPathLabel, setOriginPathLabel] = useState('Origin Path');
  const [id, setId] = useState('');
  const [idError, setIdError] = useState(false);
  const [idLabel, setIdLabel] = useState('ID');
  const [idWarning, setIdWarning] = useState('');
  const [origin, setOrigin] = useState('');
  const [originError, setOriginError] = useState(false);
  const [originLabel, setOriginLabel] = useState('Origin');
  const [checked, setChecked] = useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (!event.target.checked) {
      setOriginPath('');
      setOriginPathError(false);
      setOriginPathLabel('Origin Path');
    }
  };

  const handleClickOpen = () => {
    setId('');
    setIdError(false);
    setIdLabel('ID');
    setIdWarning('');

    setOriginPath('');
    setOriginPathError(false);
    setOriginPathLabel('Origin Path');

    setOrigin('');
    setOriginError(false);
    setOriginLabel('Origin');

    setChecked(true);
    setOpen(true);
  };

  function isEmptyOrBlank(str) {
    return !str || str.length === 0 || /^\s*$/.test(str);
  }

  const validateId = (value) => {
    const idRegex = /^[A-Za-z0-9-]+$/;
    return idRegex.test(value);
  };

  const isIdValid = () => {
    if (isEmptyOrBlank(id) || !validateId(id)) {
      setIdError(true);
      setIdLabel(
        'ID is required and must contain only letters, numbers, and dashes'
      );
      setIdWarning('ID must contain only letters, numbers, and dashes');
      return false;
    } else {
      setIdError(false);
      setIdLabel('ID');
      setIdWarning('');
      return true;
    }
  };

  const handleIdChange = (e) => {
    const { value } = e.target;
    setId(value);
    if (!validateId(value)) {
      setIdError(true);
      setIdLabel(
        'ID is required and must contain only letters, numbers, and dashes'
      );
      setIdWarning('ID must contain only letters, numbers, and dashes');
    } else {
      setIdError(false);
      setIdLabel('ID');
      setIdWarning('');
    }
  };

  const isOriginPathValid = () => {
    if (checked && isEmptyOrBlank(originPath)) {
      setOriginPathError(true);
      setOriginPathLabel('Origin path is required');
      return false;
    } else {
      setOriginPathError(false);
      setOriginPathLabel('Origin Path');
      return true;
    }
  };

  const isOriginValid = () => {
    if (isEmptyOrBlank(origin)) {
      setOriginError(true);
      setOriginLabel('Origin is required');
      return false;
    } else {
      setOriginError(false);
      setOriginLabel('Origin');
      return true;
    }
  };

  const validateOrigin = (value) => {
    const domainRegex = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.([A-Za-z]{2,6}\.?)+$/;
    return domainRegex.test(value);
  };

  const handleOriginChange = (e) => {
    const { value } = e.target;
    setOrigin(value);
    const isValid = validateOrigin(value);
    setOriginError(!isValid);
  };

  const handleBlur = () => {
    const isValid = validateOrigin(origin);
    setOriginError(!isValid);
  };

  const handleOriginPathChange = (e) => {
    const { value } = e.target;
    setOriginPath(value);
    if (!isEmptyOrBlank(value)) {
      setOriginPathError(false);
      setOriginPathLabel('Origin Path');
    }
  };

  const isValid = () => {
    if (checked) {
      return isIdValid() && isOriginValid() && isOriginPathValid();
    } else {
      return isIdValid() && isOriginValid();
    }
  };

  const handleCreate = async () => {
    if (isValid()) {
      if (validateId(id.trim())) {
        const newId = `RPAS${id.trim()}`;
        setOpen(false);
        await postData(newId, origin.trim(), originPath.trim());
        addTempToTable();
        await new Promise(resolve => setTimeout(resolve, 5000));//need 5 seconds for distId to be generated
        const distId = await getDistributionId();
        let status = await getStatus(distId);
        while (status === 'InProgress') {
          status = await getStatus(distId);
        }
        if (status === 'Deployed') {
          getDistributionData();
        }
      } else {
        setIdError(true);
        setIdLabel('ID must contain only letters, numbers, and dashes');
        setIdWarning('ID must contain only letters, numbers, and dashes');
      }
    }
  };

  const postData = async (id, origin, originPath) => {
    const path = '/rpas';
    const myInit = {
      body: {
        stackName: id.trim(),
        origin: origin,
        ...(checked && { originPath: originPath }) 
      }
    };

    return API.post(path, myInit)
    .then(function (result) {
      setResult(result); 
    })
    .catch((err) => {
      setResult(err);
    });
  };

  const addTempToTable = () => {
    const tempEntry = {
      id: `RPAS${id}`,
      domain: '',
      origin: origin,
      status: 'in progress',
      creation: new Date(),
      name: ''
    }
    setDistributions([tempEntry, ...distributions]);
  }

  const getDistributionId = async () => {
    try {
      const result = await API.get('/rpas');
      const distributions = JSON.parse(result.body);
      return distributions[distributions.length - 1].name;
    } catch (error) {
      return error;
    }
  }

  const getStatus = async (id) => {
    await new Promise((resolve) => setTimeout(resolve, 10000)); //10 second delay
    try {
      const result = await API.get(`/rpas/${id}/details`);
      const body = JSON.parse(result.body);
      return body.Distribution.Status;
    } catch (error) {
      return error;
    }
  }

  return (
    <div>
      <Tooltip title='Create Distribution'>
        <IconButton onClick={handleClickOpen} color='primary'>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Create RPaS
          <IconButton 
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the following information:
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
              label='Would you like to associate this RPAS distribution with an enclave?'
              labelPlacement='start'
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
            <TextField
              margin='dense'
              id='enclave'
              label={idLabel}
              fullWidth
              variant='outlined'
              value={id}
              onChange={handleIdChange}
              error={idError}
              helperText={idWarning}
            />
            <Tooltip
              TransitionComponent={Zoom}
              placement='top'
              title={
                !checked
                ? ''
                : 'A path that is appended to the origin when the user requests content from the origin (ex. /rdp).'
              }
            >
              <TextField
                  disabled={!checked}
                  margin='dense'
                  id='originPath'
                  label={originPathLabel}
                  type='text'
                  fullWidth
                  variant='outlined'
                  onChange={handleOriginPathChange}
                  error={originPathError}
              />
            </Tooltip>
            <Tooltip
              TransitionComponent={Zoom}
              placement='bottom'
              title='An origin is the location where content is stored and/or the domain for the application you are interfacing with (ex. test.net).'
            >
              <TextField
                margin='dense'
                id='origin'
                label={originLabel}
                type='text'
                fullWidth
                variant='outlined'
                onChange={handleOriginChange}
                onBlur={handleBlur}
                error={originError}
                helperText={
                  originError
                  ? 'Input must be non-empty and a valid domain name.'
                  : ''
                }
              />
            </Tooltip>
          </FormGroup>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button
            onClick={handleCreate}
            variant='contained'
            sx={{
              backgroundColor: '#fac90b',
              '&:hover': {
                backgroundColor: '#FEDB00',
              },
            }}
            disabled={idError || originError || (checked && originPathError)}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
